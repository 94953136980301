.pass-words {
    .container {
        overflow-x: scroll;
    }
    .fields {
        margin-bottom: 1rem;
        align-items: center;
    }
    .possibilities {
        font-style: italic;
        font-size: smaller;
    }
    .results {
        position: relative;
        outline: 1px dashed green;
        padding: 1rem;
        font-family: monospace;
        font-size: 1.25rem;
        margin-bottom: 1rem;
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;

        .password {
            cursor: pointer;
            .word {
                color: green;
            }
            .sep {
                color: orangered;
            }
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .copy {
        text-align: center;
        font-size: small;
        margin-bottom: 1rem;
    }
    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.75);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        color: green;
        line-height: 1;
        left: 0;
        top: 0;
    }
}
