.pass-words .container {
  overflow-x: scroll;
}

.pass-words .fields {
  align-items: center;
  margin-bottom: 1rem;
}

.pass-words .possibilities {
  font-size: smaller;
  font-style: italic;
}

.pass-words .results {
  outline: 1px dashed green;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  font-family: monospace;
  font-size: 1.25rem;
  display: flex;
  position: relative;
}

.pass-words .results .password {
  cursor: pointer;
}

.pass-words .results .password .word {
  color: green;
}

.pass-words .results .password .sep {
  color: #ff4500;
}

.pass-words .results .password:hover {
  text-decoration: underline;
}

.pass-words .copy {
  text-align: center;
  margin-bottom: 1rem;
  font-size: small;
}

.pass-words .overlay {
  color: green;
  background: #ffffffbf;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 2rem;
  line-height: 1;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

body {
  margin: 3rem;
  font-family: Verdana, sans-serif;
}
/*# sourceMappingURL=index.ebf57321.css.map */
